import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";

export default function ContactUs() {
    return (

        <>
            <Header />
            <div className="w-100 full-content">
                <div className="container-fluid block-space">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="w-auto text-center blocksub-title">
                                <h2 className="block-title text-uppercase">Contact Us</h2>
                                <p className="sub-title">
                                    We encouraged our players to interact with us by way of comments,
                                    reviews, feedback, questions, concerns or complaints of any issues
                                    they may face on www.viludo.com Every interaction is examined and
                                    due responses are provided by our team. We assure you that our team
                                    is committed to providing you with exceptional service and we will
                                    get back to you as soon as possible.
                                </p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-12">
                                <h3 className="text-white">Contact Form</h3>
                                <p>
                                    Contact us with your query. We would be happy to answer your
                                    questions.
                                </p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-6" style={{ marginBottom: 30 }}>
                                <div className="w-100">
                                    <form>
                                        <div className="mb-3">
                                            <label htmlFor="" className="form-label">
                                                {" "}
                                                Name:{" "}
                                            </label>
                                            <input type="text" className="form-control" id="" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="" className="form-label">
                                                {" "}
                                                Email:
                                            </label>
                                            <input type="email" className="form-control" id="" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="" className="form-label">
                                                {" "}
                                                Phone:
                                            </label>
                                            <input type="text" className="form-control" id="" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="" className="form-label">
                                                {" "}
                                                Message:
                                            </label>
                                            <textarea
                                                className="form-control "
                                                id="message"
                                                rows={5}
                                                defaultValue={""}
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-primary button-class">
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="info-block w-100">
                                    <strong>Email</strong>
                                    <a href="mailto:support@viludo.com">support@viludo.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </>
    )
}