import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router";

export default function Home() {
    const navigate = useNavigate();
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const affCode = queryParams.get('aff_code');

        setTimeout(() => {
            if (affCode) {
                navigate('/signup');
            }
        }, 1000)
    }, [navigate]);
    return (
        <>


            <Header />
            <div className="w-100">
                <img src="assets/img/banner.png" className="w-100" />
            </div>
            {/* <div class="container-fluid hero-banner">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-md-6">
				<h1>Play ViLudo. Win Cash!</h1>
				<p>A Ludo Experience Like Never Before. Play Online Ludo With Lacs of Players From India.</p>
				<span class="d-block">Outside Google Playstore App Download</span>
				<a href="#" class="download-btn">Download Now</a>
			</div>
			<div class="col-md-6">
				<img src="assets/img/header-iphone01.png">
			</div>
		</div>
	</div>
</div> */}
            <div className="container-fluid block-space how-to-play-block">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-xl-5">
                            <h2 className="block-title">India's Most Popular App</h2>
                            <p>
                                VI Ludo Games is the number 1 gaming application and in a short
                                period of time, It has evolved as the fastest growing gaming
                                platform in India. It is a gaming application with three games i.e.
                                Teenpatti, Ludo and Andar Bahar. Users can experience the best
                                interface and time-controlled gameplay with experienced players
                                across India. Let's join the VI Ludo Games community and be a part
                                of amazing and smooth gameplay.
                            </p>
                        </div>
                        <div className="col-lg-6 col-xl-7 download-img">
                            <img src="assets/img/most-app.png" className="w-100" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid amazing-features block-space">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="w-auto text-center blocksub-title">
                            <h2 className="block-title">AMAZING FEATURES</h2>
                            <p className="sub-title">Experience fair play like never before!</p>
                        </div>
                    </div>
                    <div className="row align-items-center mt-4">
                        <div className="col-md-4">
                            <ul className="exciting-formate">
                                <li className="text-end">
                                    <p>
                                        <strong className="d-block">Exciting New Format</strong>
                                        Skill-based format
                                    </p>
                                    <i className="">
                                        <img src="assets/img/new-format.png" />
                                    </i>
                                </li>
                                <li className="text-end">
                                    <p>
                                        <strong className="d-block">Play 24/7</strong>Play with verified
                                        real players any time of the day
                                    </p>
                                    <i className="">
                                        <img src="assets/img/play-247.png" />
                                    </i>
                                </li>
                                <li className="text-end">
                                    <p>
                                        <strong className="d-block">Quickest Ludo</strong>Win the game
                                        in limited number of moves and take home cash{" "}
                                    </p>
                                    <i className="">
                                        <img src="assets/img/quickest-ludo.png" />
                                    </i>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4 text-center">
                            <img src="assets/img/features.png" className="w-100" />
                        </div>
                        <div className="col-md-4">
                            <ul className="exciting-formate">
                                <li className="text-start">
                                    <i className="">
                                        <img src="assets/img/quickest-payout.png" />
                                    </i>
                                    <p>
                                        <strong className="d-block">Quickest Payout</strong>Your
                                        winnings reach your bank account instantly
                                    </p>
                                </li>
                                <li className="text-start">
                                    <i className="">
                                        <img src="assets/img/real-fun.png" />
                                    </i>
                                    <p>
                                        <strong className="d-block">100% Legal And Secure</strong>100%
                                        legal and secure for all the players
                                    </p>
                                </li>
                                <li className="text-start">
                                    <i className="">
                                        <img src="assets/img/legal-and-secure.png" />
                                    </i>
                                    <p>
                                        <strong className="d-block">Real Fun</strong>Play with real
                                        players and experience realistic gameplay{" "}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid secured-games block-space">
                <div className="container">
                    <div className="w-100 text-center">
                        <h1 className="block-title text-uppercase">
                            India’s Easiest and Most Secured Games
                        </h1>
                    </div>
                    <div className="row gaming" style={{ marginTop: 60 }}>
                        <div className="col-md-6">
                            <div className="w-100 responsible-game">
                                <img
                                    src="assets/img/Responsible.png"
                                    style={{ width: "auto", marginRight: 20 }}
                                />
                                <h2>Responsible Gaming</h2>
                                <p className="position-relative">World Class Anti-Fraud system </p>
                                <br />
                                <p className="position-relative">Strict Fair Play Gaming </p>
                                <br />
                                <p className="position-relative">Daily Deposit Limit </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="w-100 responsible-game">
                                <img
                                    src="assets/img/Safe.png"
                                    style={{ width: "auto", marginRight: 20 }}
                                />
                                <h2>100% Safe and Secure</h2>
                                <p className="position-relative">
                                    Complete Data and Identity Privacy
                                </p>
                                <br />
                                <p className="position-relative">
                                    SSL Secured &amp; RNG Certified{" "}
                                </p>
                                <br />
                                <p className="position-relative">Payment Security </p>
                            </div>
                        </div>
                    </div>
                    <div className="row gaming mb-0">
                        <div className="col-md-6">
                            <div className="w-100 responsible-game">
                                <img
                                    src="assets/img/Fastest.png"
                                    style={{ width: "auto", marginRight: 20 }}
                                />
                                <h2>Fastest Customer Support Service</h2>
                                <p className="position-relative">24x7 In-App Customer </p>
                                <br />
                                <p className="position-relative">Instant withdrawal support </p>
                                <br />
                                <p className="position-relative">Queries solved in seconds </p>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex">
                            <div className="w-100 responsible-game">
                                <img
                                    src="assets/img/Multiple.png"
                                    style={{ width: "auto", marginRight: 20 }}
                                />
                                <h2>Multiple Games</h2>
                                <p className="position-relative" style={{ minWidth: 292 }}>
                                    Ludo
                                </p>
                                <br />
                                <p className="position-relative">Teenpatti </p>
                                <br />
                                <p className="position-relative">Andar Bahar</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid install-block block-space">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="w-auto text-center">
                            <h2 className="block-title text-uppercase">testimonial</h2>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="testimonials-slide owl-carousel owl-theme">
                            <div className="item">
                                <p>
                                    Its UI is really great and interactive, Betting and winning is
                                    clear.
                                </p>
                                {/* <img src="assets/img/user.png"> */}
                                <h3 className="name">Raj</h3>
                                <h4 className="job">Nagpur</h4>
                            </div>
                            <div className="item">
                                <p>
                                    Vi Games is an amazing app, i loved playing Andar bahar, Ludo and
                                    Teenpatti in it.
                                </p>
                                {/* <img src="assets/img/user.png"> */}
                                <h3 className="name">Tanya Singh</h3>
                                <h4 className="job">Jaipur</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />



        </>


    )
}