import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './component/Header';
import Home from './component/Home';
import Footer from './component/Footer';
import './App.css';
import AboutUs from './page/AboutUs';
import HowToPlay from './page/HowToPlay';
import ContactUs from './page/ContactUs';
import PrivacyPolicy from './page/PrivacyPolicy';
import TermsCondition from './page/TermsCondition';
import Index from './signup/Index';

function App() {
  const [affcode, setAffCode] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('aff_code');
    if (code) {
      setAffCode(code);
      localStorage.setItem('aff_code', code);
    } else {
      const storedCode = localStorage.getItem('aff_code');
      setAffCode(storedCode || '');
    }
  }, []);

  useEffect(() => {
    console.log(affcode, 'aff_code____');
  }, [affcode]);

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/how-to-play' element={<HowToPlay />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-condition' element={<TermsCondition />} />
        <Route path='/signup' element={<Index affcode={affcode} />} />
      </Routes>
    </Router>
  );
}

export default App;
