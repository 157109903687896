import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";

export default function PrivacyPolicy() {
  return (
    <>
      <Header />
      <div className="w-100 full-content">
        <div className="container-fluid block-space">
          <div className="container">
            <div className="row justify-content-center">
              <div className="w-auto text-center blocksub-title">
                <h2 className="block-title text-uppercase mb-5">Privacy Policy</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p>
                  At Vi Ludo Games – the skill games are for fun &amp; excitement in
                  an understandable and trustworthy environment. As part of this, we
                  are committed to protecting the privacy of our users. The Privacy
                  Policy statement explains what information we collect from our
                  members and users &amp; how we use the information. We require the
                  users to register with us to access the games both practice &amp;
                  cash games. Personal Information of the User is information that
                  identifies the User to Vi Ludo Games, as an individual. For this, we
                  collect the following:
                </p>
                <ul style={{ paddingLeft: 15 }}>
                  <li className="text-white">Name</li>
                  <li className="text-white">Username</li>
                  <li className="text-white">Postal address</li>
                  <li className="text-white">Telephone or mobile number</li>
                  <li className="text-white">Email address</li>
                  <li className="text-white">Bank account details</li>
                  <li className="text-white">
                    Social media account ID (if permitted by the user)
                  </li>
                  <li className="text-white">Location</li>
                  <li className="text-white">Device ID</li>
                  <li className="text-white">IP address</li>
                  <li className="text-white">Date of birth</li>
                </ul>
                <p>
                  {" "}
                  If you do not provide the required information, you may not access
                  the features we provide. Such information is not associated with
                  your personal information and cannot be linked to you personally. Vi
                  Ludo Games is the sole owner of the information collected on this
                  site &amp; the above information will be only for internal use to
                  avoid fraud detection or trend analysis. We will not sell, share, or
                  rent this information to others or use it in ways different from
                  what is disclosed in this statement.
                </p>
                <p>
                  We do not disclose your personal information to any third party
                  without first receiving your permission unless mandated by law or
                  on-demand statutory authority. In this event, we shall inform you of
                  the same before disclosing the information. By registering on our
                  website, however, you grant permission for your personal information
                  to be shared and/or used as stated in this privacy policy. We may
                  need to disclose information when required by law. Vi Ludo Games has
                  the required security to protect the loss, misuse, and alteration of
                  information you provide us. Your registration data is
                  password-protected, where only you can access your information. We
                  are not responsible for any activity which is undertaken when your
                  password is used. We suggest you not disclose your password to
                  anyone.
                </p>
                <p>
                  By using our website, it is understood that you have given your
                  consent to the collection and use of this information by Vi Ludo
                  Games &amp; its partners and affiliates in the future, who shall use
                  the information strictly in terms hereof, or in terms of any
                  amendment hereto. From time to time, we may change our methods of
                  collecting information and modify our privacy policy. We will post
                  those changes on this page so that you are always aware of what
                  information we collect, how we use it, and under what circumstances
                  we disclose it.
                </p>
                <p>
                  We use cookies and other technologies such as pixel tags and clear
                  gifs to store certain types of information each time you visit any
                  page on our website. Cookies enable this website to recognize the
                  information you have consented to give to this website and help us
                  determine what portions of this website are most appropriate for
                  your professional needs. We may also use cookies to serve
                  advertising banners to you. These banners may be served by us or by
                  a third party on our behalf. These cookies will not contain any
                  personal information. Whether you want your web browser to accept
                  cookies or not is up to you. If you haven’t changed your computer’s
                  settings, most likely, your browser already accepts cookies. If you
                  choose to decline cookies, you may not fully experience all the
                  features of the website. You can also delete your browser cookies or
                  disable them entirely. Vi Ludo Games cookies are kept for a lifetime
                  on customers ‘devices. But this may significantly impact your
                  experience with our website and may make parts of our website
                  nonfunctional or inaccessible. Therefore, we recommend that you
                  leave them turned on. We use third-party service providers to serve
                  ads on our behalf across the Internet and sometimes on this site
                  using a pixel tag or SDK, which is industry-standard technology used
                  by most major websites. No personally identifiable information is
                  collected or used in this process. They do not know the name, phone
                  number, address, email address, or personally identifying
                  information about the user.
                </p>
                <div className="sub_head"> Mobile Number Verification</div>
                <p>
                  At Vi Ludo Games, You are required to validate your Mobile number
                  for playing Cash games. This is to safeguard fraud of any form at Vi
                  Ludo Games. All your information is kept confidential &amp; not
                  shared with any person or party for a given reason. It is a
                  mandatory process for all cash users to follow the process of mobile
                  authentication at Vi Ludo Games.
                </p>
                <p>
                  Notwithstanding anything to the contrary in this policy, we may
                  preserve or disclose your information if we believe that it is
                  reasonably necessary to comply with a law, regulation, or legal
                  request; to protect the safety of any person; to address fraud,
                  security, or technical issues; or to protect Vi Ludo Games rights or
                  property. However, nothing in this Privacy Policy is intended to
                  limit any legal defenses or objections that you may have to a third
                  party’s including a government’s request, to disclose your
                  information. Vi Ludo Games uses reasonable organizational,
                  technical, and administrative measures to protect information under
                  our control.
                </p>
                <p>
                  We use reasonable organizational, technical, and administrative
                  measures to protect Personal Information under our control. However,
                  no data transmission over the Internet or data storage system can be
                  guaranteed to be 100% secure. Therefore, if Users have reason to
                  believe that their interaction with Vi Ludo Games features is no
                  longer secure (for example, if they feel that the security of any
                  account they might have with Vi Ludo Games has been compromised),
                  they must immediately notify Vi Ludo Games of the problem by
                  contacting the customer service personnel. Vi Ludo Games shall take
                  the necessary action as Vi Ludo Games may deem fit under the
                  circumstances. Vi Ludo Games shall not be held responsible for any
                  activity in your account resulting from your failure to keep your
                  personal or other information secure.
                </p>
                <p>
                  Once you register with our services, you are made available with the
                  necessary tools and account settings to access or change the
                  personal information you have provided to us under your
                  registration, which is associated with your account.
                </p>
                <p>
                  This Privacy policy is to be read conjointly with Terms of Use.
                  Wherever applicable, the meaning to the terms of this policy shall
                  be derived by a conjoint reading with that of the Terms of Use of Vi
                  Ludo Games.
                </p>
                <p>
                  We may amend or revise this Privacy Policy from time to time. The
                  most current version of the policy will govern our use of your
                  information and your services. By continuing to access or use the
                  services after such changes become effective, you agree to be bound
                  by the revised/amended Privacy Policy.
                </p>
                <p>
                  The laws of India shall govern this Privacy Policy. All disputes,
                  claims, causes relating to and arising out of this Privacy Policy
                  shall be subject to the exclusive jurisdiction of the Courts in
                  India.
                </p>
                <p>For any query, you can write to support@viludo.com</p>
                <p>For more details, please refer to our Terms of service. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

    </>

  )
}