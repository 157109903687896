import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
    return (
        <div className="container-fluid header" style={{ background: "#4633af" }}>
            <div className="container">
                <div className="row">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <Link className="navbar-brand" to="index-2">
                            <img src="assets/img/logo.png" />
                        </Link>
                        <button
                            className="navbar-toggler" style={{ filter: ' invert(100%)' }}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div
                            className="collapse navbar-collapse justify-content-end"
                            id="navbarNav"
                        >
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link
                                        className="nav-link active"
                                        aria-current="page"
                                        to="/"
                                    >
                                        Home
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/how-to-play">
                                        How to Play
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/about-us">
                                        About Us
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/contact-us">
                                        Contact Us
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link vi-down-btn" href="https://tinyurl.com/56rpwusu">
                                        Download Now
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>

    )
}