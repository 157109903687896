import React from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";

export default function HowToPlay() {
    return (

        <>
        <Header />
            <div className="w-100 full-content">
                <div className="w-100">
                    <img src="assets/img/howtoplay.png" className="w-100" />
                </div>
                <div className="container-fluid pt-5">
                    <div className="container">
                        <div className="row  justify-content-center">
                            <div className="col-md-12 how-tabs">
                                <ul
                                    className="nav nav-tabs d-flex justify-content-center"
                                    id="myTab"
                                    role="tablist"
                                >
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link active"
                                            id="home-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#home"
                                            type="button"
                                            role="tab"
                                            aria-controls="home"
                                            aria-selected="true"
                                        >
                                            Ludo
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="profile-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#profile"
                                            type="button"
                                            role="tab"
                                            aria-controls="profile"
                                            aria-selected="false"
                                        >
                                            Andar Bahar
                                        </button>
                                    </li>
                                    {/* <li class="nav-item" role="presentation">
    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Rummy</button>
  </li> */}
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="contact-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#teenpatti"
                                            type="button"
                                            role="tab"
                                            aria-controls="teenpatti"
                                            aria-selected="false"
                                        >
                                            Teen Patti
                                        </button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id="home"
                                        role="tabpanel"
                                        aria-labelledby="home-tab"
                                    >
                                        <p className="">
                                            Ludo is a board and dice game, in which players have to
                                            strategize their moves to move towards victory. Surely everyone
                                            has played this game in their childhood. A lot of our good
                                            memories are attached to this game and get triggered when
                                            hearing the name Ludo.{" "}
                                        </p>
                                        <p>
                                            Ludo originated from a square board game called Pachisi which
                                            was initially played by Royal Mughal Emperors. Then in 1896, in
                                            England, the name Ludo was officially patented for the game and
                                            it has been known since. The simplicity and fast-paced nature of
                                            the game attract everyone and it is loved by all ages. Up to 2-4
                                            players can play the game. The basic aim of Ludo is to play the
                                            pawns in the decided path and lead them towards the center of
                                            the board, in the Home space..{" "}
                                        </p>
                                        <div className="sub_head">Components</div>
                                        <div className="sub_head" style={{ fontSize: 15 }}>
                                            The game consists of several components which by default comes
                                            within a single set of the game in the market. These components
                                            are:
                                        </div>
                                        <p className="m-0">
                                            1. A SQUARE BOARD WITH PATHS, HOME SPACE, AND 4 PERSONAL HOME
                                            SPACES OF DIFFERENT COLORS DESIGNED ON IT.
                                        </p>
                                        <p className="m-0">
                                            2. 4 DIFFERENT COLORED PAWNS. SIMILAR COLORS AS OF THEIR
                                            PERSONAL HOME SPACES.
                                        </p>
                                        <p>3. ONE OR TWO DICE.</p>
                                        <div className="sub_head">How to Play</div>
                                        <p>
                                            Ludo is one of the simplest games ever and learning this game is
                                            so fun and easy. Four players choose their respective colors of
                                            the pawn and the pawns, in the beginning, are at rest in their
                                            Personal Homes. Each player gets 4 pawns and can use any pawn to
                                            move forward upon the number of the rolled dice, given that the
                                            pawns which they want to move are out of their respective
                                            Personal Homes. The dice is rolled and the player who gets the
                                            number 6 on their dice moves first. As the game progresses, all
                                            the pawns of all the players get out of their Personal Homes and
                                            will move in the spaces provided on the board. The main
                                            objective of the game is to reach first at the center of the
                                            board where the Home space is allocated. But the catch is you
                                            can get cut off by another player’s pawn on your way to the
                                            center. You can also cut-off anyone’s pawn coming in your way.
                                            The cut off pawn returns to their Personal Home and will only
                                            get when the rolled dice gives the number 6. If a player gets a
                                            6 on the rolled dice, he/she gets a second chance to roll the
                                            dice.
                                        </p>
                                        <p>
                                            There are also shared spaces on the track spaces where every
                                            pawn is safe from the others and any number of pawns can sit in
                                            this space at a time.{" "}
                                        </p>
                                        <div className="sub_head">How can you win the game?</div>
                                        <p>
                                            Getting a 6 on the dice is something that can’t be controlled,
                                            it purely depends on luck. However, moving the pawns after
                                            getting 6 or any numbers on the rolled dice is something that
                                            can be controlled. Strategize a few steps ahead in your mind and
                                            look out for the smartest move possible when your turn comes up.
                                            Always keep an eye out for other pawns as they can cut you off
                                            in the middle of your journey towards victory and now you will
                                            have to start from the beginning.{" "}
                                        </p>
                                        <div className="sub_head" style={{ fontSize: 15 }}>
                                            Variants of the Ludo Game
                                        </div>
                                        <p>
                                            Want to try something new and different while maintaining the
                                            classic feels of the Ludo game? Well, we provide a few variants
                                            of the game for the players who seek to play something out of
                                            the box.{" "}
                                        </p>
                                        <div className="sub_head">Variants include:</div>
                                        <div className="sub_head" style={{ fontSize: 15 }}>
                                            4 House
                                        </div>
                                        <p>
                                            The rules for this game remain the same as of the base game
                                            except for the winning condition of this game. The player now
                                            has to end up with all 4 of their pawns in the Home space. The
                                            player should be the first one to do so. Each player gets 15 sec
                                            to roll the dice and move the desired pawn. The pawn gets opened
                                            when the rolled dice gives the number 6 on its face. The Timer
                                            is a new variation of the game. As the name suggests, a
                                            predefined time is set before the game session and the game
                                            follows a point system. Each step rewards 1 point and reaching
                                            the Home space will reward you 56 points, a single pawn. The
                                            pawns at the beginning are open already, you just have to walk
                                            them towards the Home space in the given time. When the timer
                                            hits 00:00, the player with maximum points wins the game.{" "}
                                        </p>
                                        <div className="sub_head" style={{ fontSize: 15 }}>
                                            1 House
                                        </div>
                                        <p>
                                            The game starts normally and sticks to all the basic rules of
                                            the Ludo game. The player starts as the dice hits the number 6.
                                            Players have to reach the Home space with 1 pawn only. The first
                                            player to do so will be declared the winner. Each player gets 15
                                            sec each to roll the dice and play their pawns. Private table
                                            Private tables are fully customizable. They can be 4 houses, 1
                                            House, Timer, etc. whatever you desire. You can invite your
                                            friends, family, etc. to play with them on this personalized
                                            table. You can create your own game.{" "}
                                        </p>
                                        <p>
                                            <strong>Private table</strong> Private tables are fully
                                            customizable. They can be 4 houses, 1 House, Timer, etc.
                                            whatever you desire. You can invite your friends, family, etc.
                                            to play with them on this personalized table. You can create
                                            your own game.
                                        </p>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="profile"
                                        role="tabpanel"
                                        aria-labelledby="profile-tab"
                                    >
                                        <p>
                                            {" "}
                                            A single 52-card deck can be used for a quick shot game. To keep
                                            it nice and simple, the chances of winning in the game are
                                            50/50. Players can then choose from two sides of the table,
                                            which can be Andar (on the left) or Bahar (on the right). When
                                            cutting and distributing cards, the goal is to predict which
                                            side of the selected card will have a card with the same number.{" "}
                                        </p>
                                        <div className="sub_head">Know the Basic Rules</div>
                                        <ul className="" style={{ paddingLeft: 15 }}>
                                            <li>
                                                The games start by the dealer dealing one face-up card which
                                                he or she places in the middle of the table in front of the
                                                player.
                                            </li>
                                            <li>
                                                This face-up card is called the game card and it is what will
                                                determine how the round is played and when it ends.
                                            </li>
                                            <li>
                                                {" "}
                                                Next, it’s the player’s turn to place his bet. The goal is to
                                                predict on which side – andar or bahar – that the next card
                                                with the same value will show up.
                                            </li>
                                            <li>
                                                {" "}
                                                After that, the dealer proceeds with dealing one card at the
                                                time on each side of the table until a card with the same
                                                value as the game card is dealt.
                                            </li>
                                            <li>
                                                {" "}
                                                If the player placed his bet on andar and the same-valued card
                                                is dealt on andar he wins but if it’s dealt on the bahar side,
                                                he loses.
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="contact"
                                        role="tabpanel"
                                        aria-labelledby="contact-tab"
                                    >
                                        <p className="cn_regular mt-2">
                                            These are the guidelines for you to follow after which you not
                                            only will learn how to play rummy games but also become an
                                            expert in this game.
                                        </p>
                                        <h4 className="sub_head">HOW TO PLAY RUMMY GAME</h4>
                                        <p className="cn_regular mt-2">
                                            Rummy is a simple card game that is played with two or more
                                            players. It requires 2 decks of cards if the number of players
                                            exceeds five. First player at a table arranges the cards which
                                            are dealt into predefined combinations and submits the melded
                                            cards. Your primary aim is to be the first players as the rest
                                            of the players accumulate points based on the cards in their
                                            hands. The player who has the least number of points at the end
                                            of a game wins the game.
                                        </p>
                                        <h4 className="sub_head">RUMMY CARD GAME RULES -</h4>
                                        <p className="cn_regular mt-2">
                                            Thirteen ranks of each of the four suits and additional 2 jokers
                                            are included in 52 cards deck.
                                        </p>
                                        <p className="cn_regular">
                                            four card suits are: clubs (♣), diamonds (♦), hearts (♥) and
                                            spades (♠)
                                        </p>
                                        <p className="cn_regular">
                                            cards in each suit are ace, 2, 3, 4, 5, 6, 7, 8, 9, and 10,
                                            jack, queen, and king. here face cards are jack, queen and king
                                            are and ace can be used either as 1 or as a face card when
                                            making the sequences.
                                        </p>
                                        <p className="cn_regular">
                                            joker: jokers act as a replacement or substitute for any card in
                                            melding sets or sequences.
                                        </p>
                                        <p className="cn_regular">
                                            points associated with cards: face cards – 10 points are
                                            associated with each face card and the rest of the cards have
                                            points according to the number printed. no points are associated
                                            with printed jokers or wild jokers.
                                        </p>
                                        <h4 className="sub_head">How to Play on The Vi Ludo Games</h4>
                                        <p className="cn_regular">
                                            Although the game on The Vi Ludo Games has adequate pointers on
                                            the table that tells you about each action you may take, but
                                            below is a quick guide on how to play rummy on a table online.
                                        </p>
                                        <p />
                                        <h4 className="sub_head">Picking Cards Up:</h4>
                                        <p className="cn_regular">
                                            You have picked two cards up from options namely– the closed
                                            deck or the open deck.
                                        </p>
                                        <p className="cn_regular mt-2">
                                            Closed Deck : The remaining cards from the deck which has not
                                            been dealt with by the players are closed deck. The topmost card
                                            on the deck, is unknown to the players.
                                        </p>
                                        <p className="cn_regular mt-2">
                                            Open Deck : The pile of cards discarded per player per round is
                                            an open deck, these cards are visible to the players.
                                        </p>
                                        <h4 className="sub_head">Rules for picking up cards:</h4>
                                        <p className="cn_regular mt-2">
                                            You can pick only one card at a time when it is your turn.
                                        </p>
                                        <p className="cn_regular mt-2">
                                            You can only pick the topmost card from Closed Deck or Open
                                            Deck, you can choose the pile.
                                        </p>
                                        <h4 className="sub_head">Card Combinations</h4>
                                        <p className="cn_regular mt-2">
                                            There are two types of winning card combinations in a game of
                                            rummy when you finally meld your cards while placing a Show:
                                        </p>
                                        <h4 className="sub_head">Sequence/Runs</h4>
                                        <p className="cn_regular mt-2">
                                            When you have 3 or more cards of the same suit in consecutive
                                            order.
                                        </p>
                                        <p className="cn_regular mt-2">
                                            For Example: Jack of Diamonds, Queen of Diamonds and King of
                                            Diamonds and can together form a sequence -J♦Q♦K♦ Similarly
                                            Three of Spades, Four of Spades, Five of Spades, and Six of
                                            Spades can together form a sequence - 3♥ 4♥ 5♥ 6♥
                                        </p>
                                        <h4 className="sub_head">Sets/Trails</h4>
                                        <p className="cn_regular mt-2">
                                            When you have 3 or more cards of the same rank but of different
                                            suits, you cannot use two cards of the same value in a single
                                            set.
                                        </p>
                                        <p className="cn_regular mt-2">
                                            For Example: Jack of Clubs, Jack of Spades and Jack of Hearts
                                            can together form a set - J♣J♠J♥
                                        </p>
                                        <p className="cn_regular mt-2">
                                            Similarly, Eight of Diamonds, Eight of Clubs, Eight of Spades
                                            and Eight of Hearts can together form a set - 8♦8♣8♠8♥
                                        </p>
                                        <h4 className="sub_head">Printed Joker &amp; Wild Joker</h4>
                                        <p className="cn_regular mt-2">
                                            Jokers can be termed as wild cards as they play a very important
                                            role in the way your rummy game proceeds and in melding your
                                            cards as soon as possible.
                                        </p>
                                        <p className="cn_regular mt-2">
                                            There are 2 types of Jokers in Rummy namely– Printed Joker and
                                            Wild Joker.
                                        </p>
                                        <h4 className="sub_head">Printed Joker</h4>
                                        <p className="cn_regular mt-2">
                                            Every deck of cards 52 cards has 2 extra cards which are known
                                            as Printed Jokers and they can be used to make sequences or sets
                                            once a pure sequence has been formed
                                        </p>
                                        <img src="assets/img/printed-joker.png" className="mt-1" />
                                        <h4 className="sub_head">Using Jokers to form Sequence/Sets</h4>
                                        <p className="cn_regular mt-2">
                                            Below is how a Printed Joker can be used to form sequences and
                                            sets:
                                        </p>
                                        <img src="assets/img/crd2.png" className="mt-1" />
                                        <p className="cn_regular mt-2">
                                            Forming an sequence with Printed Joker
                                        </p>
                                        <img src="assets/img/crd3.png" className="mt-1" />
                                        <p className="cn_regular mt-2">
                                            Forming an set with Printed Joker
                                        </p>
                                        <h4 className="sub_head">Sort Function</h4>
                                        <p className="cn_regular mt-2">
                                            In The Vi Ludo Games the cards are distributed randomly between
                                            all the opponents and appear in the fashion as shown below. You
                                            can easily arrange your cards by hitting the sort button. This
                                            feature sorts the cards according to their suits in increasing
                                            order of their ranks as shown. Using Sort makes it easier for
                                            the user to have a quicker understanding of what cards they
                                            have, strategize and decide how they want to proceed with the
                                            game.
                                        </p>
                                        <img src="assets/img/crd4.png" className="sub_head" />
                                        <p className="cn_regular mt-2">
                                            Cards as they are served on the table
                                        </p>
                                        <img src="assets/img/crd5.png" className="sub_head" />
                                        <p className="cn_regular mt-2">
                                            Cards after using the Sort feature
                                        </p>
                                        <h4 className="sub_head">Declare</h4>
                                        <p className="cn_regular mt-2">
                                            Sometimes when your opponent has a valid meld and you need to
                                            declare your cards.
                                        </p>
                                        <p className="cn_regular mt-2">
                                            Do you think that you’re being watched? You can hide your cards
                                            by simply clicking on the Flip button. When you enable this your
                                            cards will no longer be visible to anyone until it’s your turn
                                            to play next.
                                        </p>
                                        <h4 className="sub_head">Drop/Auto-Drop Function</h4>
                                        <p className="cn_regular mt-2">
                                            As soon as players have been dealt, you may be able to drop a
                                            game if you’re displeased with the cards you’ve received.
                                            However, this will give you a bunch of undesirable points, so it
                                            needs to be a calculated risk! (Don’t try this on your first
                                            date!)
                                        </p>
                                        <h4 className="sub_head">Time-bound</h4>
                                        <p className="cn_regular mt-2">
                                            The thing which separates it from offline rummy is that you
                                            cannot take your own sweet time for every move. This adds to the
                                            thrill of your skill!
                                        </p>
                                        <h4 className="sub_head">Discard a Card</h4>
                                        <p className="cn_regular mt-2">
                                            Select the card that you want to discard by clicking on it and
                                            hit the discard icon that appears above the card. The discarded
                                            card will then move to the Open deck and your opponent may
                                            either pick this card or pick a card from Closed Deck in their
                                            next turn.
                                        </p>
                                        <img src="assets/img/crd6.png" className="sub_head" />
                                        <p className="cn_regular mt-2">Place a Show</p>
                                        <p className="cn_regular mt-2">Group Cards</p>
                                        <p className="cn_regular mt-2">
                                            Select 2 or more cards that you want to group by clicking on
                                            them. After picking your combination, click on Group icon that
                                            appears on top of the cards.
                                        </p>
                                        <img src="assets/img/crd7.png" className="sub_head" />
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="teenpatti"
                                        role="tabpanel"
                                        aria-labelledby="contact-tab"
                                    >
                                        <div className="sub_head">Teen Patti</div>
                                        <p className="text-white">
                                            Teen Patti is a popular Indian gambling card game just like
                                            poker. It is normally played in a group of 3 to 6 persons and
                                            uses&nbsp;a 52-card pack without jokers. Each&nbsp;player is
                                            dealt three cards face down. Before the cards are dealt,
                                            the&nbsp;<b className="hcolr">boot</b>&nbsp;amount is decided
                                            and collected from each player. The boot amount is the minimum
                                            stake amount put in the&nbsp;<b className="hcolr">pot</b>, which
                                            is the money kept in the centre of the table. As the game
                                            progresses the pot money grows and is won by the&nbsp;
                                            <b className="hcolr">winner</b>&nbsp;of that hand. Winner is the
                                            player who remains in the game till the&nbsp;completion of the
                                            hand and has the best hand or the highest hand&nbsp;based
                                            on&nbsp;the cards ranking shown below.
                                        </p>
                                        <p className="text-white">
                                            Ranking of the cards from high to low is:
                                        </p>
                                        <ol className="" style={{ paddingLeft: 15 }}>
                                            <li className="text-white">
                                                Trail or Set (three of same rank),
                                            </li>
                                            <li className="text-white">Pure sequence,</li>
                                            <li className="text-white">Sequence (or run),</li>
                                            <li className="text-white">Color,</li>
                                            <li className="text-white">
                                                Pair (two cards of same rank), and
                                            </li>
                                            <li className="text-white">High Card.</li>
                                        </ol>
                                        <p className="text-white">
                                            The figure below shows highest to lowest cards of each rank. It
                                            should be noted that in a sequence A-K-Q is the highest ranked
                                            sequence while A-2-3 is the second highest ranked sequence.
                                        </p>
                                        <img
                                            style={{ borderRadius: 6, marginBottom: 15 }}
                                            src="assets/img/sw.png"
                                            alt=""
                                        />
                                        <p className="text-white">
                                            <b className="hcolr">Playing Teen Patti.</b> Each player
                                            contributes the boot money and gets three cards facedown. Now it
                                            is the turn of the player next to the dealer in clockwise
                                            direction. The player has option to place a bet without seeing
                                            the cards <b className="hcolr">(blind)</b> or see the card. When
                                            the player sees his card, he/she can play{" "}
                                            <b className="hcolr">chaal</b> or may have other options
                                            depending upon the progress of the game. A player who places a
                                            blind bet is referred to as the{" "}
                                            <b className="hcolr">blind player.</b> A player who places his
                                            bet after seeing the cards is referred to as the{" "}
                                            <b className="hcolr">seen player.</b>
                                        </p>
                                        <p className="text-white">
                                            <b className="hcolr">
                                                <i>Blind Player.</i>
                                            </b>{" "}
                                            To be blind player, you must not see your cards. You have
                                            options to play <b className="hcolr">Pack, Blind</b> and{" "}
                                            <b className="hcolr">Show</b> if available. To play Blind put
                                            the <b className="hcolr">bet</b> amount in the pot. The Blind
                                            bet amount is equal to the current{" "}
                                            <b className="hcolr">stake</b> amount or twice the current stake
                                            amount. The current stake amount, in case of the first player,
                                            is the boot amount. Thus if the first player is a blind player,
                                            the player must bet amount equal to the boot amount or twice the
                                            boot amount.
                                        </p>
                                        <p className="text-white">
                                            <i>Stake Amount.</i> The bet placed by a blind player becomes
                                            the stake amount for the next player while half of the bet
                                            placed by a seen player becomes the stake amount for the next
                                            player. In case of the next blind players, the bet amount can be
                                            equal to the stake amount or twice the stake amount.
                                        </p>
                                        <p className="text-white">
                                            A blind player can ask for a Show only when there is one
                                            opponent remaining in the game. This is a blind show. After
                                            asking for show, the cards of both the players become visible
                                            and the winner gets the pot.
                                        </p>
                                        <p className="text-white">
                                            <b className="hcolr">
                                                <i>Seen Player.</i>
                                            </b>{" "}
                                            A seen player can play Chaal, Pack or may have other options.
                                            The other options that may be available to the seen player are
                                            Show and Side Show. Once you have seen your cards, to remain in
                                            the game, you must play chaal unless you select any other
                                            available option.
                                        </p>
                                        <p className="text-white">
                                            <i>(a) Chaal.</i> To remain in the game, a seen player must play
                                            Chaal or Side Show if available. For chaal the player must put
                                            the bet amount in the bot. The bet amount for a seen player is
                                            equal to twice or four times the current stake amount. If the
                                            previous player is a blind player, the stake amount for next
                                            player is amount bet by the player. If the previous player is a
                                            seen player, the stake amount for next player is half the amount
                                            bet by the player.
                                        </p>
                                        <p className="text-white">
                                            <i>(b) Side Show.</i> A seen player can ask for a Side Show.
                                            Side Show means that you wish to compare your cards with the
                                            cards of the previous player. You can do this only if the
                                            previous player is also a seen player and there are one or more
                                            other players still in the game. For Side Show you put amount
                                            equal to twice the current stake in the pot. Your request for
                                            the Side Show is conveyed to the previous player. The previous
                                            player has option to accept or deny your Side Show request.
                                        </p>
                                        <p className="text-white">
                                            If your Side Show request is accepted and the previous player
                                            has better cards than you, you must pack. If your cards are
                                            better than the previous player, the previous player must pack.
                                            After one of you have packed, the turn passes on to the next
                                            player.
                                        </p>
                                        <p className="text-white">
                                            If your Side Show request is denied, you do not see each other’s
                                            cards and both of you remain in the game and the turn passes on
                                            to the next player.{" "}
                                        </p>
                                        <p className="text-white">
                                            <b className="hcolr">
                                                Limited Stake and Unlimited Stake Tables.
                                            </b>
                                            &nbsp;When you play Teen Patti for the first time, you play on a
                                            limited stake table. On the first limited stake table that you
                                            play, the boot amount is 2, you can play maximum 4 blinds, the
                                            maximum Chaal that you can play is 256 and the Pot limit is
                                            2048. When the Pot limit is reached, all players still in the
                                            game are forced to show their cards and the winner gets the Pot.
                                        </p>
                                        <p className="text-white">
                                            In the next level limited table, the the boot amount is 4, you
                                            can play maximum 4 blinds, the maximum Chaal that you can play
                                            is&nbsp;512 and the Pot limit is 4096. Players who have played
                                            many hands and have many chips play on higher level tables.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
<Footer />
        </>
    )
}