import React, { useEffect, useState } from "react";
import './formstyle1.css';
import FooterLogo from './footerlogo1.png';

export default function Index({ affcode }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [showCongratulations, setShowCongratulations] = useState(false);
    //console.log(affcode, 'affcodeaffcode')
    const [loader, setLoader] = useState(false);

    const handleSubmit = async () => {
        const validUserName = /^[a-z][a-z0-9_]+$/;
        setLoader(true);

        if (username.length < 7 || password.length < 7) {
            setError('Username and password must be at least 7 characters long');
            setLoader(false);
            return;
        }

        if (!validUserName.test(username)) {
            setError('Username must start with a lowercase letter and can only contain lowercase letters, numbers, and underscores');
            setLoader(false);
            return;
        }

        if (!username || !password) {
            setError('Please enter both username and password');
            setLoader(false);
            return;
        }
        setError('');

        const requestBody = {
            "operator_id": process.env.REACT_APP_OPERATOR_ID,
            "username": username,
            "password": password,
            "country_code": "+91",
            "new_user": true,
            "currency": "INR",
            "login_type": "username",
            "device_details": "{}",
            "aff_code": affcode,
            "referred_by": ""
        };

        console.log(requestBody, 'requestBody')

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BASE_URL_PORT}/login/username`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody)
            });

            console.log(response, 'response')

            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setShowCongratulations(true);
                setLoader(false);
            } else {
                const errorData = await response.json();
                setError(errorData.message || 'Login failed. Please try again.');
            }
        } catch (err) {
            console.error("Error during login:", err);
            setError('Error occurred. Please try again later.');
        }
    };

    return (
        <>
            <div className="formFull signupForm" style={{ position: "relative" }}>
                {!showCongratulations ? (
                    <div className="w-100 numberSection">
                        <div className="w-100" style={{ marginBottom: '20px' }}>
                            <img src='/assets/img/logo.png' alt="Logo" />
                        </div>
                        <div className="w-100">
                            <h2 style={{ margin: "15px 0" }}>Welcome to Vi Ludo</h2>
                        </div>
                        <div className="w-100">
                            <input
                                type="text"
                                name="name"
                                placeholder="Enter username"
                                id="name"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                maxLength={8}
                            />
                            <input
                                type="password"
                                name="password"
                                placeholder="Enter password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                maxLength={8}
                            />
                        </div>

                        <div className="validationText33" style={{ color: "red", fontSize: 12 }}>
                            {error && <p className="m-0 pt-0" style={{ color: 'red' }}>{error}</p>}
                        </div>
                        <div className="w-100 d-flex justify-content-center">
                            <button className="submit d-flex align-items-center justify-content-center" id="submitBtn" onClick={handleSubmit}>
                                {loader ? (<div className="spinner-border" role="status">
                                    <span className="sr-only"></span>
                                </div>) : (
                                    'Submit'
                                )}

                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="w-100 congratulation">
                        <div className="w-100">
                            <img src='/assets/img/logo.png' alt="Logo" />
                        </div>
                        <div className="w-100" style={{ marginTop: '20px' }}>
                            <p style={{ fontWeight: "bold", color: "#ff9700" }}>
                                Download and Start Playing
                            </p>
                        </div>
                        <div className="w-100">
                            <a href="https://tinyurl.com/56rpwusu" id="filedownload" style={{ marginTop: 50 }} className="verify">
                                Download &amp; Play
                            </a>
                        </div>
                    </div>
                )}
                <div className="w-100">
                    <p className="footer-info">
                        <span style={{ color: "#ff9700" }}>Note:</span> Please verify the same
                        number which is already registered with us.
                    </p>
                </div>
            </div>
        </>
    );
}
