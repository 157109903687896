import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";

export default function AboutUs() {
    return (
        <>
            <Header />
            <div className="w-100 full-content">
                <div className="container-fluid block-space">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="w-auto text-center blocksub-title">
                                <h2 className="block-title text-uppercase mb-5">About Us</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p>
                                    The viludo.com is a full-fledged online gaming website. We are
                                    committed to provide our users with an unmatched virtual and
                                    friendly gaming experience. We provide skill based games to our
                                    Users. We are the lovers of gaming that is why we know what a player
                                    wants from a general gaming site which makes us customer-oriented
                                    website. To know more about our Bonuses, Tournaments and upcoming
                                    events, check out our 'Promotions' page. We are sure you will have a
                                    wonderful experience on The Vi Ludo Games.
                                </p>
                                <p>
                                    {" "}
                                    We have designed our website to provide our users the simplest, most
                                    informative and realistic Online Gaming Website.{" "}
                                </p>
                                <p>
                                    {" "}
                                    Whether you are a novice or a champ at playing games, you are at the
                                    right place. We offer a seamless and comprehensive experience of
                                    playing online gaming in a way that is completely Safe, Secure,
                                    Trusted, and Full of Fun. Paid version of card game are available on
                                    our website. We are using latest technology in order to provide our
                                    users with a fun and safe gaming environment and also achieve a goal
                                    of complete engagement of the players, with an amazing Gaming
                                    Experience.{" "}
                                </p>
                                <p>
                                    {" "}
                                    The Vi Ludo Games website has been designed keeping in mind various
                                    important aspects of the game and the players, in order to provide a
                                    realistic experience of playing on a virtual platform.{" "}
                                </p>
                                <p>
                                    {" "}
                                    In The Background Vi Ludo Games is a gaming site founded in 2023,
                                    believes in the highest standards of business ethics. Vi Ludo Games
                                    is Random Number Generator (RNG) compatible. We have also tied a
                                    number of banks and payment systems to process payments to our
                                    customers.{" "}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

        </>


    )
}