import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <>
            <div className="footer container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <img src="assets/img/logo.png" width={150} align="Vi Ludo Games" />
                            <strong className="d-block footer-sub-title">Follow Us</strong>
                            <div className="social-links text-left text-md-right pt-md-3 pt-md-0">
                                <a href="#" className="twitter">
                                    <i className="fa fa-twitter" />
                                </a>
                                <a href="#" className="facebook">
                                    <i className="fa fa-facebook" />
                                </a>
                                <a href="#" className="instagram">
                                    <i className="fa fa-instagram" />
                                </a>
                                <a href="#" className="linkedin">
                                    <i className="fa fa-linkedin" />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-3 mt-4 mt-md-0">
                            <h2 className="footer-title">Useful Links</h2>
                            <ul className="footer-nav mb-0">
                                <li>
                                    <Link to="/about-us">About Us</Link>
                                </li>
                                <li>
                                    <Link href="/contact-us">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h2 className="footer-title d-none d-md-block" style={{ minHeight: 21 }} />
                            <ul className="footer-nav">
                                <li>
                                    <Link to="/privacy-policy">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="/terms-condition">Terms and Conditions</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h2 className="footer-title">CUSTOMER SERVICE</h2>
                            <ul className="footer-nav footer-contact">
                                <li>
                                    <a href="mailto:support@viludo.com">
                                        <i className="fa fa-envelope" /> &nbsp;support@viludo.com
                                    </a>
                                </li>
                            </ul>
                            <strong
                                className="d-block footer-sub-title"
                                style={{ marginTop: 30 }}
                            >
                                {" "}
                                Security &amp; Certificates:
                            </strong>
                            <ul
                                className="foot-img p-0"
                                style={{ marginTop: 10, listStyle: "none" }}
                            >
                                <li>
                                    <img src="assets/img/4.png" className="us" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row justify-content-center copyright-block">
                        <div className="w-auto">
                            <p>© 2023 ViLudo Games. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="down-footer-btn-sec">
                <div className="container-fluid">
                    <a className="down-footer-btn" href="https://tinyurl.com/56rpwusu">
                        <i className="fa fa-android" aria-hidden="true" /> Download
                    </a>
                </div>
            </div>
        </>


    )
}